import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import reviews from '../data/reviews';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import about from '../img/about.jpg';

function AboutUs() {
	return (
		<>
			<Helmet>
				<title>About Us &bull; Sled Restaurant & Bar Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				pageName={'About us'}
				subtitle={'our little story'}
				icon={<FontAwesomeIcon icon={solid('store')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section>
					<h2 className="txt-center">Who we are</h2>
					<h3 className="subtitle txt-center mb-2">Our Mission</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

					<img src={about} className="mb-2 rounded" alt="Tables in the restaurant" />

					<div className="grid">
						{reviews.map((review, idx) => 
							<Review
								key={idx}
								img={review.img}
								fullname={review.name}
								stars={review.stars}
								review={review.text}
							/>
						)}
					</div>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default AboutUs;