import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import AboutUs from './pages/AboutUs';
import Menus from './pages/Menus';
import Services from './pages/Services';
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Faqs from './pages/Faqs';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';


export default  function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location])

	return (
		<AnimatePresence>
			<Routes location={location} key={location.key}>
				<Route path='/' element={<HomeNav />} />
				<Route path='about-us' element={<AboutUs />} />
				<Route path='menus' element={<Menus />} />
				<Route path='services' element={<Services />} />
				<Route path='team' element={<Team />} />
				<Route path='gallery' element={<Gallery />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='contact' element={<Contact />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}