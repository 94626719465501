import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import services from '../data/services';
import HeaderPage from '../components/HeaderPage';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

function Services() {
	return (
		<>
			<Helmet>
				<title>Our Services &bull; Sled Restaurant & Bar Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				pageName={'Our Services'}
				subtitle={'different from others'}
				icon={<FontAwesomeIcon icon={solid('handshake')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>We love relaxation</h2>
					<h3 className="subtitle mb-2">Feel at home</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

               <div className="grid mb-2">
                  {services.map((service, idx) =>
                     <article key={idx}>
                        <i className="badge-icon mb-2">
									{service.icon}
								</i>
                        <h3 className="subtitle">{service.subtitle}</h3>
                        <h2>{service.title}</h2>
                        <p>{service.text}</p>
                     </article>
                  )}
               </div>
				</section>

            <CallToAction
               title={'What are you waiting for?'}
               text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
               toPage={'/contact'}
               label={'Contact Now'}
            />

			</motion.main>

			<Footer />
		</>
	);
}

export default Services;