import './HomeNav.css';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import logo from '../img/logo.svg';

function HomeNav() {
   const visible = useVisible(15);
   const mainNav = useRef();

   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX,
               containerRect = container.getBoundingClientRect(),
               containerCenterX = containerRect.left + containerRect.width / 2,
               distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   
   return (
      <>
         <Helmet>
            <title>Sled Restaurant & Bar Demo &bull; React Multipurpose Template</title>
         </Helmet>

         <motion.header
            className="logo"

            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img src={logo} alt="Sled Logo" />
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}

            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/about-us'>
                        <section>
                           <h1>About us</h1>
                           <p className="badge">our little story</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('store')} />
                           <h3>Who we are</h3>
                           <p>Our Mission</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/menus'>
                        <section>
                           <h1>Our Menus</h1>
                           <p className="badge">delicious choices</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('utensils')} />
                           <h3>Dishes & drinks</h3>
                           <p>Our specialties</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/services'>
                        <section>
                           <h1>Our Services</h1>
                           <p className="badge">different from others</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('handshake')} />
                           <h3>We love relaxation</h3>
                           <p>Feel at home</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/team'>
                        <section>
                           <h1>Team</h1>
                           <p className="badge">a strong union</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('users')} />
                           <h3>We are a family</h3>
                           <p>Linked by passion</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/gallery'>
                        <section>
                           <h1>Gallery</h1>
                           <p className="badge">look here</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('eye')} />
                           <h3>See for yourself</h3>
                           <p>While we are at work</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/faqs'>
                        <section>
                           <h1>Faqs</h1>
                           <p className="badge">the answers to your doubts</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('question')} />
                           <h3>Frequently Asked Questions</h3>
                           <p>Exhaustive answers</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/contact'>
                        <section>
                           <h1>Contact us</h1>
                           <p className="badge">leave a message</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('phone')} />
                           <h3>Get in Touch</h3>
                           <p>We will get back to you!</p>
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}

export default HomeNav;