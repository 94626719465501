import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';


export default [
   {
      icon: <FontAwesomeIcon icon={solid('wifi')} />,
      subtitle: 'Free',
      title: 'Wi-fi',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('book-open')} />,
      subtitle: 'Angle of silence',
      title: 'Read book',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('child')} />,
      subtitle: 'Play area',
      title: 'Fun for children',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   },
   {
      icon: <FontAwesomeIcon icon={solid('check')} />,
      subtitle: 'Where you want',
      title: 'Take away',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
   }
];