import gallery1 from '../img/gallery/gallery1.jpg';
import gallery2 from '../img/gallery/gallery2.jpg';
import gallery3 from '../img/gallery/gallery3.jpg';
import gallery4 from '../img/gallery/gallery4.jpg';
import gallery5 from '../img/gallery/gallery5.jpg';
import gallery6 from '../img/gallery/gallery6.jpg';
import gallery7 from '../img/gallery/gallery7.jpg';
import gallery8 from '../img/gallery/gallery8.jpg';
import gallery9 from '../img/gallery/gallery9.jpg';
import gallery10 from '../img/gallery/gallery10.jpg';
import gallery11 from '../img/gallery/gallery11.jpg';
import gallery12 from '../img/gallery/gallery12.jpg';
import gallery13 from '../img/gallery/gallery13.jpg';
import gallery14 from '../img/gallery/gallery14.jpg';
import gallery15 from '../img/gallery/gallery15.jpg';
import gallery16 from '../img/gallery/gallery16.jpg';


export default [
   {
      src: gallery1,
      alt: 'Waitress with beers'
   },
   {
      src: gallery2,
      alt: 'Dish with shrimp and rocket'
   },
   {
      src: gallery3,
      alt: 'Raspberry pie'
   },
   {
      src: gallery4,
      alt: 'Restaurant tables room'
   },
   {
      src: gallery5,
      alt: 'Skewers of meat and vegetables'
   },
   {
      src: gallery6,
      alt: 'Coffee machine'
   },
   {
      src: gallery7,
      alt: 'Blueberry, banana and honey brunch'
   },
   {
      src: gallery8,
      alt: 'Dinner table'
   },
   {
      src: gallery9,
      alt: 'Brunch with eggs'
   },
   {
      src: gallery10,
      alt: 'Dinner tables at the restaurant'
   },
   {
      src: gallery11,
      alt: 'Meat dish with cooked vegetables'
   },
   {
      src: gallery12,
      alt: 'Waiter with dishes'
   },
   {
      src: gallery13,
      alt: 'Risotto with eggs and courgettes'
   },
   {
      src: gallery14,
      alt: 'Beer plug'
   },
   {
      src: gallery15,
      alt: 'Gourmet dish'
   },
   {
      src: gallery16,
      alt: 'Big salad'
   }
];